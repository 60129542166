.navigation{
    width: 100%;
    padding: 20px 0px 20px 0px;
     margin-bottom: 40px;
      background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 70%, rgba(255,255,255,0) 100%);
}

.navigation-large {
    width: 100%;
 
  
    img{
        max-width: 100px;
    }
    .navlink{
        padding-top: 30px;
         .tospace{
            margin: 0px 100px 0px 100px;
        }
        a {
            font-family: $font-rubik;
            color: $color-text;
            text-decoration: none;
            margin-top: 10px;
            font-size: 30px;
      
        }
        .nav-active {
            color: $color-title;
            &::after {
                color: $color-title;

            }
        }
    }
    .loupe {
        max-width: 40px;
        padding-top: 30px;
        margin-left: 35px;

    }
    a:hover {
        color: $color-lightYellow;
    }

    .inputMenu{
        padding-top: 40px;
        input{
            background-color: $color-grey;
            border: none;
            
            color: $color-title;
            padding-left: 10px;
        }
        
    }
}


.menu-to-hide{
    display: none;
}
.navigation-small{
    display: none;
}
 @media only screen and (max-width: 965px) {
     .menu-small-button{
         background: none;
         border: none;
     }
    .navigation-small{
        display: block;
        padding: 0px 10px 0px 10px;
        .menu-small-icon{
            width: 40px;
            height: 40px;
            
        }
        .menu-small-icon-search{
            max-width: 25px;
            margin-bottom: -34px !important;
            margin-left: 5px !important;
        }
        .menu-small-logo{
            width: 60px;
            margin-bottom: 5px;
        }
    }
    .navigation-large{
        display: none;
    }
    .menu-to-hide{
        text-align: center;
        a{
            color: $color-text;
            text-decoration: none;
            font-family: $font-arial;
            font-size: 28px;
        }
        a:hover{
            color: $color-lightYellow;
        }
        .nav-active{
            color: $color-title;
        }
        
    }
    .inputMenu{
        padding-top: 20px;
        input{
            background-color: $color-grey;
            border: none;
            
            color: $color-title;
            padding-left: 10px;
        }
    }
}
.particle {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  background-repeat: no-repeat;
  background-size: contain;
}