@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap");
@import url("https://use.typekit.net/xld5lsz.css");

$font-1: "Poppins", sans-serif;
$font-ganache: ganache, sans-serif;
$font-arial: arial, sans-serif;
$font-rubik: rubik, sans-serif;
 
$color-text: #F7EED1;
$color-lightYellow : #F3D36C;
$color-title: #FCB600;
$color-red : #A72608;
$color-grey: #2D2D2D;
$color-3: #282c34;
$color-4: rgb(32, 35, 42);
$color-background-black-95 : rgba($color: #000000, $alpha: .60);
 
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  font-family: $font-rubik;
  color: $color-3;
  background-image: url("../../public/images/background-theatre.jpg");
  // background-size: cover;
  // background-repeat: repeat-y;
  background-attachment: fixed;
  margin-bottom: 0;
}
li {
  list-style-type: none;
}
a {
  text-decoration: none;
  color: $color-3;
  cursor: pointer;
}
a:visited {
  color: $color-3;
}
.footer{
  font-family: $font-arial;
  background-image: url("../../public/images/siege.jpg");
  background-size: cover;
  background-repeat: no-repeat;

  
}
