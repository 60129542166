.about-container{
    .pop-about-title{
        max-width: 5%;
        img{
            max-width: 100%;
        }
    }
    h1{
        padding: 100px 0px 100px 0px;
        text-align: center;
        font-family: $font-ganache;
        color: $color-title;
        font-size: 80px;
    }
    
    .pop-about-line-bottom{
        margin-bottom: 100px;
    }
    .about-card-container{
        max-width: 1400px;
        margin: auto;
        margin-bottom: 100px;
    }
    .about-card{
        text-align: center;
        max-width: 30%;
        background-color: #07070771;
        border: 2px solid $color-title;
        img{
            max-width: 70%;
        }
       h2{
           font-family: $font-ganache;
           color: $color-title;
           margin-bottom: 40px;
           margin-top: 40px;
       }
       p{
           font-family: $font-arial;
           color: $color-text;
           font-size: 22px;
       }
       .card-last-p{
        margin-bottom: 50px;
       }
    }
     .left-about-card{
        margin-right: 50px;
    }
    .right-about-card{
        margin-left: 50px;
    }
    .about-text-container{
        background-color: #07070771;
        border: 2px solid $color-title;
        max-width: 950px;
        padding: 30px;
        margin: auto;
        margin-bottom: 200px;
        p{
            font-family: $font-arial;
            color: $color-text;
            font-size:22px;
            font-weight: 300;
        }
    }
}
@media only screen and (max-width: 700px) {
     
    

    .about-container{
    .about-card-container{
       flex-direction: column !important;
    }
       .about-card{
           max-width: 80%;
           width: 100%;
          
        
       }
        .right-about-card{
            margin-left: 0px;
            margin: auto;
        }
         .left-about-card{
            margin-right: 0px;
            margin: auto;
            margin-bottom: 50px;
        }
    }
}