.actorsContent{
    color: white; 
    width: 100%;
    margin-left: 5%;

    h2{
        font-size: 18px;
        font-style: italic;
        font-weight: 300;
    }
    h1{
        margin-bottom: 15px;
        text-align: center;
        font-weight: 300;
    }
    p{
        color: $color-text;
        font-size: 22px;
    }
}
.actorImg{
    max-width: 50%;
    img{
        max-width: 70%;
    }
}
.actorYellowText{
    color: $color-title;
}
.movie_desc{
    max-width: 1400px;
    margin: auto;
    padding: 30px;
    background-color: $color-background-black-95 !important;
    margin-bottom: 30px;
   
    h2{
        color: $color-title;
        margin-bottom: 40px;
    }
    p{
        color: $color-text;
    }
}
.actorCreditMovie{
    max-width: 300px;
}
.credit-list-container{
    max-width: 1400px;
    margin: auto;
    padding: 30px;
    background-color: $color-background-black-95;
    border-radius: 5px 5px 0px 0px;
    .filmographieTable{
        color: $color-text;
        font-family: $font-arial;
        .headerFilmo{
            color: $color-title;
        }
        .years-title{
            width: 15%;
        }
        .content-filmo-line{
            border-bottom: 1px solid $color-text;
            cursor: pointer;
        }
    }
    .filmoTitle{
        color: $color-title;
        margin-left: 15px;
    }
}
