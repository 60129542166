.search-movie-header{
    max-width: 1400px;
    margin: auto;
    background-color: #07070771 !important;
    padding: 30px;
    text-align: center;
    h1{
        text-align: center;
        color: $color-title;
        font-family: $font-arial;

    }
    .input-section{
        
        input{
            background-color: $color-grey;
            border: none;
            
            color: $color-title;
            padding-left: 10px;
        }
        .searchIcon{
            max-width: 24px;
            background-color: $color-title;
            padding-bottom: 15px;
            height: 24px;
        }
        img{
            max-width: 80%;margin-bottom: 5px;

        }
    }
    // .input-section :first-child{
    //     width : 40%;
    // }
    
}
.nosearch{
    margin: 80px 0px 80px 0px;
    h4{
        text-align: center;
        color: $color-title;
        font-size: 30px;
    }
}
@media only screen and (max-width: 820px) {
    .nosearch{
        margin: 100px 0px 100px 0px;
    }
}
