section{
  margin-top: 40px;
}
.card {
  background-color: #07070700 !important;
  margin: 10px 4px;
  transition: .6s ease;
  border: none !important;
}
.card-img-top{
   border: 2px solid $color-title;
}
.card:hover {
  transform: scale(1.05);
}

.card-block {
   padding: 10px;
}
.card-title{
  color: $color-title;
  font-family : $font-arial;
  text-align: center;
  // overflow-wrap: normal !important; 
  // white-space: normal !important;
  // height: 58px;
}


::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent;
}

div.scrollcards .card {
    display: inline-block !important;
    padding: 14px;
    text-decoration: none;
    height: auto; 
    width: 300px;
}
.mamarg {
margin-top: -40px;

}
