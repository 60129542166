.footer {
    width: 100%;
    display: inline-flex;
    top: 1100px;
    height: 210px;
    align-items: center;
    justify-content: center;
   
    -webkit-box-shadow: 0px -21px 15px 2px #0F0100; 
    box-shadow: 0px -21px 15px 2px #0F0100;
   
    filter: #07070771;
    p,
    a {
        color: $color-title;
        letter-spacing: 1px;
        font-size: 20px;
        text-align: center;
    }

    .slogan {
        font-family: $font-ganache;
        font-size: 35px;
    }

    img {
        width: 100px;
    }
}
@media only screen and (max-width: 820px) {
    .footer{
       p{font-size: 18px;}
        .slogan{
            font-size: 24px;
        }
    }
}