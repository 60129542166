.imgContainer{
    width: 40%;
    img{
        max-width: 100%;
        border: 2px solid $color-title;
    }
}
.movie_header{
    background-color: $color-background-black-95 !important;
    max-width: 1400px;
    margin: auto;
    padding: 45px;
    border-radius: 5px 5px 0px 0px;
}
.movieContent{
    color: white; 
    width: 100%;
    margin-left: 5%;

    h2{
        font-size: 30px;
        font-style: italic;
        font-weight: 300;
        margin-bottom: 30px;
    }
    h1{
        margin-bottom: 15px;
    }
    p{
        font-size: 22px;
    }
}
.lightYellow{
    color: $color-text;
}
.genreDetails{
    margin-right: 30px;
    font-style: italic;
    color: $color-title;
    cursor: pointer;
}
.movieDetailsInfos{
    color: $color-text;
    font-size: 20px;
    font-weight: 300;
    margin-right: 15px;
}
.noteSection{
    margin-top: 30px;
    h3{
        color: $color-text;
        font-size: 22px;

    }
    p{
        color: $color-title;
        margin-top: 10px;
    }
    .trailerButton{
        padding: 5px;
        background-color: $color-title;
        color: $color-grey;
        border-radius: 10px;
        button{
            background: none;
            border: none;
            margin: 0px 10px 0px 10px;
        }
        a{
            text-decoration: none;
            color: black;
        }
    }
    img{
        max-height: 60px;
    }
}
.overviewContainer{
    background-color: $color-background-black-95 !important;
    max-width: 1400px;
    margin: auto;
    padding: 30px;
    margin-bottom: 30px;
    h1{
        color: $color-title;
        margin-bottom: 30px;
    }
    p{
        color: $color-text;
    }
}
.actorsContainer{
    .titleDivActor{
         background-color: $color-background-black-95 !important;
         max-width: 1400px;
         margin: auto;
         padding: 30px;
         
    }
    h1{
        color: $color-title;
        margin-bottom: 30px;
    }
    .card-block{
        background-color: $color-title;
    }
    .actorNameContainers{
        background-color: $color-title;
        max-width: 100%;
        margin: 5px;
        h1{
            font-size: 22px;
            color: white;
            word-wrap: nowrap;
        }
    }
}
