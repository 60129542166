// section{
//   margin-top: 40px;
// }
// .card {
//   margin: 10px 4px;
//   transition: .6s ease;
// }

// .card:hover {
//   transform: scale(1.05);
// }

// .card-block {
//    padding: 10px;
// }
.container-fluid{
  background-color: $color-background-black-95 !important;
  margin-bottom: 80px;
}
.scrollcards {
  // overflow: auto;

  white-space: nowrap;
  overflow-x: auto;
}
.slider-title{
  color: $color-title;
  font-family : $font-arial;
  padding: 15px 0px 0px 8%;
}
.movieHomeSection{
  background-color: #07070771 !important;
  margin-bottom: 50px;
  h1{
    margin-left: 10%;
    font-family: $font-arial;
    color: $color-title;
    padding: 20px;
  }
}
// ::-webkit-scrollbar {
//     width: 0px;
//     height: 0px;
//     background: transparent;
// }

// div.scrollcards .card {
//     display: inline-block;
//     padding: 14px;
//     text-decoration: none;
//     height: auto; 
//     width: 300px;
// }
