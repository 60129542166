.sliderMovieCard{
  width: 300px;
  margin: 10px;
  img{
    min-width: 100%;
    border: 2px solid $color-title;
  }
  
}
.main{
  overflow-x: scroll;
  display: flex;
}
.sectionMovie{
  display: flex;
  justify-content: center;
  align-items: center;
}