.sort-container{
    background-color: $color-background-black-95 !important;
    margin: 0px 15px 0px 15px;
    width: 15%;
    float: left;
    // height: auto;
    select{
        text-align: center;
        margin: auto;
    }
    h4{
        text-align: center;
        font-family: $font-ganache;
        color: $color-title;
        font-size: 52px;
        margin: 15px 0px 15px 0px;
    }
    .divButtonLeft{
        text-align: center;
    }
    button{
        background-color: #07070700;
        font-family: $font-arial;
        color: $color-title;
        font-size: 30px;
        border:none;
        text-align: center;
        display: block;
        margin: auto;
    }
}
.limit{
    width: 310px !important;
}
.selectedCat{
    color: $color-lightYellow;
}
.smallCat{
    display: none;
}
.catButton{
    font-family: $font-ganache;
    font-size: 30px;
}
.selectedGenres{
    color: $color-lightYellow;
}
 @media only screen and (max-width: 1220px) {
    .bigCat{
        display: none;
    }
    .smallCat{
        display: block;
        width: 100%;
    }
    .switchHide{
        display: none;
    }
}
.catactiv {
    color: $color-text !important;
    text-decoration: underline;
    font-style: bold !important;
}
.titrecat:hover {
    cursor: pointer;
}